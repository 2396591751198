import {
  ChakraProviderProps,
  extendTheme,
  withDefaultColorScheme,
  ChakraProvider,
  cookieStorageManager,
  ColorModeWithSystem,
  ColorModeScript,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { useGlobalConfig } from "src/hooks/useGlobalConfig";

import { theme as atotalTheme } from "./atotal-theme";
import { theme as generaliTheme } from "./generali-theme";

export const MyChakraProvider: React.FC<ChakraProviderProps> = ({
  children,
}) => {
  const { tenant } = useGlobalConfig();

  let initialColorMode: ColorModeWithSystem | undefined = undefined;
  initialColorMode = cookieStorageManager.get() || "system";
  const theme = tenant === "atotal" ? atotalTheme : generaliTheme;

  const _theme = useMemo(
    () =>
      extendTheme(
        {
          ...theme,
          // do not set this on the global theme object, because it is shared on the server between requests
          // we copy it here to make sure each request has its own config
          config: { initialColorMode },
        },
        withDefaultColorScheme({ colorScheme: "brand" })
      ),
    [initialColorMode, theme]
  );

  //TODO: remove this line when we have a dark mode
  _theme.config.initialColorMode = "light";
  return (
    <>
      <ColorModeScript
        type="cookie"
        initialColorMode={theme.config.initialColorMode}
      />
      <ChakraProvider theme={_theme} colorModeManager={cookieStorageManager}>
        {children}
      </ChakraProvider>
    </>
  );
};
