import { Box } from "@chakra-ui/react";
import React from "react";
import "./style.css";

import { TranslationProvider } from "./intl";
import { MyChakraProvider } from "./theme/provider";

export function Wrapper({ children }: { children: React.ReactNode }) {
  return (
    <>
      <style>
        {`
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        `}
      </style>

      <TranslationProvider>
        <MyChakraProvider>
          {/* <ColorModeToggle /> */}
          <Box animation="fadeIn 500ms" h="full">
            {children}
          </Box>
        </MyChakraProvider>
      </TranslationProvider>
    </>
  );
}
