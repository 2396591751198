import { createInstance } from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import huAtotal from "../intl/hu-atotal";
import huGenerali from "../intl/hu-generali";

import { useGlobalConfig } from "src/hooks/useGlobalConfig";

export const initI18next = (lng: string) => {
  const instance = createInstance({
    resources: {
      // en: {
      //   translation: en,
      // },
      hugenerali: {
        translation: huGenerali,
      },
      huatotal: {
        translation: huAtotal,
      },
    },
    interpolation: {
      escapeValue: false,
    },
  }).use(initReactI18next);
  instance.init({
    lng,
  });
  return instance;
};

export const TranslationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { language, tenant } = useGlobalConfig();

  return (
    <I18nextProvider i18n={initI18next(`${language}${tenant}`)}>
      {children}
    </I18nextProvider>
  );
};
