import { useMemo } from "react";
import type { GlobalConfig } from "src/+data";
import { useData } from "vike-react/useData";

export type TenantConfig = {
  mainLogo: string;
  favicon: string;
  name: string;
};

export const useGlobalConfig = () => {
  const data = useData() as GlobalConfig;

  const tenantConfig: TenantConfig = useMemo(() => {
    switch (data.tenant) {
      case "generali":
        return {
          name: "Generali",
          mainLogo: "generali-logo.svg",
          favicon: "generali-logo.svg",
        };
      case "atotal":
      default:
        return {
          name: "Atotal",
          mainLogo: "atotal-logo.png",
          favicon: "atotal-icon.png",
        };
    }
  }, [data]);

  return { ...data, tenantConfig };
};
