const locales = {
  signIn: "Bejelentkezés",
  forgotPassword: "Elfelejtett jelszó",
  "Sign in": "Bejelentkezés",
  "Sign in with password": "Bejelentkezés",
  Submit: "Elküld",
  Password: "Jelszó",
  recovery: "Jelszó visszaállítás",
  changePassword: "Jelszó megváltoztatása",
  Save: "Mentés",
  "New password": "Új jelszó",
  required: "Kötelező mező",
  "Sign in for Internal employees": "Generali Belépés",
  Back: "Vissza",
  Continue: "Tovább",
};

export default locales;
