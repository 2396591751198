import { Box, Flex, Center } from "@chakra-ui/react";
import { useGlobalConfig } from "src/hooks/useGlobalConfig";

export const Layout = ({ children }) => {
  const { tenantConfig } = useGlobalConfig();
  return (
    <Box h={"100%"} w={"100%"}>
      <Flex maxW={"1000px"} h={"100%"} w={"100%"} mx="auto">
        <Center
          w={"50%"}
          _dark={{
            bgGradient: "linear(to-br, #04aa5d, #1e3739 66%)",
          }}
        >
          <img width={"80%"} src={tenantConfig?.mainLogo} alt="" />
        </Center>

        <Center w={"50%"} pos="relative">
          <Box
            w={"80%"}
            maxW={"400px"}
            rounded="xl"
            p={4}
            boxShadow="0 0 5px 0px #0000000f"
            bg="gray.50"
            minH={200}
          >
            {children}
          </Box>
        </Center>
      </Flex>
    </Box>
  );
};
