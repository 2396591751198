import "@fontsource/source-sans-pro/400.css";
import "@fontsource/source-sans-pro/600.css";

import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

export const brandGradient =
  "linear-gradient(to bottom, var(--chakra-colors-brand-400), var(--chakra-colors-brand2-400) 100%);";

const styles = {
  global: (props) => ({
    body: {
      color: mode("gray.800", "whiteAlpha.900")(props),
      bg: mode("#F6F6F7", "#161616")(props),
    },
    "*": {
      borderColor: mode("#00004020", "#fff1")(props),
    },
    // "::-webkit-scrollbar": {
    //   // width: "8px",
    // },
    // "::-webkit-scrollbar-track": {
    //   bg: mode("gray.100", "gray.700")(props),
    //   // borderRadius: 10,
    // },
    // "::-webkit-scrollbar-thumb": {
    //   bg: mode("gray.400", "gray.500")(props),
    //   // borderRadius: 10,
    //   // border: "2px solid transparent",
    //   // backgroundClip: "content-box",
    // },
    // "::-webkit-scrollbar-thumb:hover": {
    //   bg: mode("gray.400", "gray.600")(props),
    // },
    // "::-webkit-scrollbar-thumb:active": {
    //   bg: mode("gray.500", "gray.700")(props),
    // },
  }),
};
//'button', 'list', 'item', 'groupTitle', 'command', 'divider'
const components = {
  Menu: {
    baseStyle: {
      item: {
        transitionProperty: "none",
        // fontWeight: "600",
        // color: "brand.900",

        _hover: {
          bg: "brand.100",
          // color: "white",
          // filter: "saturate(2.5)",
        },

        _focus: {
          bg: "brand.100",
          // color: "white",
        },

        _active: {
          bg: "brand.100",
          // color: "white",
        },

        rounded: "md",
      },
      list: {
        p: 2,
        zIndex: 100,
      },
    },
  },
  Select: {
    baseStyle: {
      field: {
        bg: "brand.100!important",
      },
      icon: {
        bg: "brand.100!important",
      },
    },
  },
  //'container', 'label', 'closeButton'
  Tag: {
    baseStyle: {
      container: {
        // backgroundColor: "brand.100",
        fontWeight: "bold",
      },
      // label: {
      //   color: "white",
      // },
      // closeButton: {
      //   color: "white",
      //   opacity: 1,
      // },
    },
  },
  Tooltip: {
    baseStyle: {
      rounded: "md",
    },
  },
  //0 4px 12px -5px var(--chakra-colors-brand-600)
  //'control', 'icon', 'container', 'label'
  Checkbox: {
    baseStyle: (props) => {
      return {
        control: {
          rounded: "md",
          transition: "filter 0.2s",
          _indeterminate: {
            bg: "linear-gradient(210deg, var(--chakra-colors-brand-500), var(--chakra-colors-brand2-500) 250%);",
            border: "none",
            boxShadow: `0 4px 8px -5px var(--chakra-colors-${
              props.colorScheme || "brand"
            }-600)`,
          },
          _checked: {
            boxShadow: `0 4px 8px -5px var(--chakra-colors-${
              props.colorScheme || "brand"
            }-600)`,
            bg: "linear-gradient(210deg, var(--chakra-colors-brand-500), var(--chakra-colors-brand2-500) 250%);",
            border: "none",
            _hover: {
              bg: "linear-gradient(210deg, var(--chakra-colors-brand-500), var(--chakra-colors-brand2-500) 250%);",
              filter: "saturate(1.3)",
            },
          },
        },
        icon: {},
      };
    },
    defaultProps: {
      // bg: "linear-gradient(210deg, var(--chakra-colors-brand-500), var(--chakra-colors-brand2-500) 250%)",
      size: "lg",
    },
  },
  Button: {
    baseStyle: {
      // backgroundColor: "red!important",
      _active: {
        // transform: "translateY(1px)",
      },

      // transitionProperty: "none",
    },
    variants: {
      outline: {
        color: "gray.800",
        // borderWidth: "2px",
      },
      solid: (props) => {
        const isBrandColor =
          !props.colorScheme || props.colorScheme === "brand";
        const isGray = props.colorScheme === "gray";
        if (isGray) {
          return;
        }
        const isDisabled = props.disabled || props.isDisabled;
        return {
          transitionProperty: "all",

          _disabled: {
            _hover: {
              bg: isBrandColor
                ? "linear-gradient(to bottom, var(--chakra-colors-brand-500), var(--chakra-colors-brand-500) 100%)!important;"
                : props.bg,
            },
            bg: isBrandColor
              ? "linear-gradient(to bottom, var(--chakra-colors-brand-500), var(--chakra-colors-brand-500) 100%);"
              : props.bg,
          },

          ...(!isDisabled && {
            // bg: isBrandColor
            //   ? "linear-gradient(to bottom, var(--chakra-colors-brand-500), var(--chakra-colors-brand-500) 100%);"
            //   : props.bg,
            // backgroundColor: "brand.100",
            // border: "1px solid",
            // rounded: "sm",
            // color: "brand.800",
            bg: isBrandColor
              ? "linear-gradient(210deg, var(--chakra-colors-brand-500), var(--chakra-colors-brand2-500) 250%);"
              : props.bg,
            // boxShadow: `inset 0 0 50px 50px #FFF1, 0 4px 14px -6px var(--chakra-colors-${
            //   props.colorScheme || "gray"
            // }-600)`,
            _hover: {
              bg: isBrandColor
                ? "linear-gradient(210deg, var(--chakra-colors-brand-500), var(--chakra-colors-brand2-500) 250%);"
                : props.bg,
              // boxShadow: `inset 0 0 50px 50px #FFF1, 0 4px 16px -6px var(--chakra-colors-${
              //   props.colorScheme || "brand"
              // }-600)`,
              // borderColor: "transparent",
              // transform: "translateY(-1px)",
              color: "white",
              filter: "saturate(1.3)",
            },
            _active: {
              bg: isBrandColor
                ? "linear-gradient(210deg, var(--chakra-colors-brand-600), var(--chakra-colors-brand2-600) 250%);"
                : `${props.colorScheme}.600`,
              // bg: `${props.colorScheme || "brand"}.600`,
              filter: "saturate(1)",
              // boxShadow: "none",
              transform: "translateY(0px)",
            },
          }),
        };
      },
      glass: {
        bg: "#4448",
        backdropFilter: "blur(20px)",
        color: "white",
        _hover: {
          bg: "brand.600",
          _disabled: {
            bg: "#4448",
          },
        },
        _active: {
          bg: "brand.700",
        },
      },
      simple: {
        bg: "transparent",
        color: "gray.800",
        _hover: {
          bg: "gray.100",
        },
        _active: {
          bg: "gray.200",
        },
        border: "1px solid",
      },
    },
  },
  Drawer: {
    // setup light/dark mode component defaults
    baseStyle: (props) => ({
      dialog: {
        bg: mode("white", "#161616")(props),
      },
    }),
  },
  Modal: {
    // setup light/dark mode component defaults
    baseStyle: (props) => ({
      dialog: {
        bg: mode("white", "#161616")(props),
      },
    }),
  },
  Input: {
    defaultProps: {
      focusBorderColor: "brand.500",
    },
  },
};

export const statusColors = {
  Open: {
    bg: "rgba(54, 162, 235, 0.2)",
    color: "rgba(54, 162, 235, 1)",
  },
  Pending: {
    bg: "rgba(75, 192, 192, 0.2)",
    color: "rgba(75, 192, 192, 1)",
  },
  Closed: {
    bg: "rgba(154, 230, 180, 0.2)",
    color: "rgba(72, 187, 120, 1)",
  },
};

const brand2 = {
  50: "#f0f9ff",
  100: "#e0f2fe",
  200: "#bae6fd",
  300: "#7dd3fc",
  400: "#38bdf8",
  500: "#0ea5e9",
  600: "#0284c7",
  700: "#0369a1",
  800: "#075985",
  900: "#0c4a6e",
};

const brand = {
  50: "#f0fdfa",
  100: "#ccfbf1",
  200: "#99f6e4",
  300: "#5eead4",
  400: "#2dd4bf",
  500: "#14b8a6",
  600: "#0d9488",
  700: "#0f766e",
  800: "#115e59",
  900: "#134e4a",
};
const gray = {
  50: "#FCFCFC",
  100: "#E8E8E8",
  200: "#DADADA",
  300: "#D0D5D9",
  400: "#B0B5B6",
  500: "#808686",
  600: "#757575",
  700: "#616161",
  800: "#424242",
  900: "#212121",
};

const withColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    brand,
    brand2,
    gray,
    Open: {
      100: theme.colors["red"][100],
      400: theme.colors["red"][400],
      500: theme.colors["red"][500],
    },
    Pending: {
      100: theme.colors["orange"][100],
      400: theme.colors["orange"][400],
      500: theme.colors["orange"][500],
    },
    Closed: {
      100: theme.colors.blue[100],
      400: theme.colors.blue[400],
      500: theme.colors.blue[500],
    },
  },
});

const fonts = {
  heading: `'Source Sans Pro', sans-serif`,
  body: `'Source Sans Pro', sans-serif`,
};

const radii = {
  // none: "0",
  // sm: "0",
  // base: "0",
  // md: "4px",
  // lg: "12px",
  // xl: "0",
};

const shadows = {
  // this is the shadow when you press tab on the keyboard to focus on an element
  // by default it would be light blue
  // outline: "0 0 0 3px rgba(20, 184, 166, .6)",
};

export const theme = extendTheme(
  {
    shadows,
    components,
    styles,
    fonts,
    radii,
  },
  { config: { initialColorMode: "light" } },
  withColors,
  withDefaultColorScheme({ colorScheme: "brand" })
);
